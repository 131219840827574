<template>
  <div class="card mb-g">
    <!--        <div class="alert alert-warning" v-if="verifying || (!verifying &&verified && reject)">-->
    <!--                Bạn cần hoàn tất thủ tục xác minh danh tính-->
    <!--              </div>-->
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form class="form" id="contract_create" @submit.prevent="handleSubmit(startUpload)">
    <div class="card-body pb-0 px-4">
      <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0">
        <h5 class="mb-0 flex-1 text-dark fw-500">
          Xác minh danh tính
        </h5>
      </div>
      <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
        <div class="row" :class="classDefine[checkCandidate]">
          <div class="col-sm-4" v-if="checkCandidate">
            <div class="img_profile">
              <img class="image_general" src="media/profile_verify.png">
            </div>
          </div>
          <div v-if="verifying" class="col-sm-4 content_verify">
            <h6 class="text-center">Tải lên thẻ căn cước và ảnh cá nhân</h6>
            <div class="row front_image">
              <div class="col-sm-12">
                <el-upload
                    action=""
                    accept="image/jpeg,image/gif,image/png"
                    :headers="headers"
                    :class="{ hide: hideInputUpload }"
                    list-type="picture-card"
                    ref="upload"
                    :on-change="handleChange"
                    :file-list="fileList"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove">
                  <i class="el-icon-upload"></i>
                  Tải lên <b class="focus-guide">{{ textGuideUpload }}</b>
                </el-upload>
                <!--eslint-disable-->
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </div>
            </div>
          </div>
          <div class="col-md-8" v-if="!checkCandidate">
            <!--            // lan 1-->
            <div class="row">
              <div class="col-md-4 ">
                <div class="mt-2">
                  <label class="form-label">Họ và tên <span class="text-danger">(*)</span></label>
                  <validation-provider vid="name" name="Tên" rules="required" v-slot="{ errors, classes }">
                    <input type="text" class="form-control" id="name" placeholder="Họ và tên"
                           autocomplete="false" v-model="user.name" :class="classes" disabled>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="mt-2">
                  <label class="form-label">Văn phòng <span class="text-danger">(*)</span></label>
                  <validation-provider vid="name" name="Tên" rules="required" v-slot="{ errors, classes }">
                    <input type="text" class="form-control" id="name" placeholder="Văn phòng"
                           autocomplete="false" v-model="user.profile.branch" :class="classes" disabled>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="mt-2" v-if="checkPassCandidate">
                  <label class="form-label">Địa chỉ <span class="text-danger">(*)</span></label>
                  <validation-provider vid="address" name="Địa chỉ" rules="required" v-slot="{ errors, classes}">
                    <input v-model="user.profile.address" type="text" :class="classes" class="form-control" id="address"
                           placeholder="Nhập địa chỉ thường trú">
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mt-2">
                  <label class="form-label">Số điện thoại <span class="text-danger">(*)</span></label>
                  <validation-provider vid="address" name="Địa chỉ" rules="required" v-slot="{ errors, classes}">
                    <input v-model="user.profile.phone" type="text" :class="classes" class="form-control" id="address"
                           placeholder="Số điện thoại" disabled>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="mt-2" v-if="checkPassCandidate">
                  <label class="form-label">Giới tính <span class="text-danger">(*)</span></label>
                  <ValidationProvider vid="gender" name="Giới tính" rules="required"
                                      v-slot="{ errors, classes }">
                    <edutalk-select id="gender" v-model="user.profile.gender" class="w-100"
                                    placeholder="Chọn giới tính" :class="classes" ref="gender" name="gender">
                      <edutalk-option
                          v-for="item in [{id: 1, name: 'Nam'}, { id: 0, name: 'Nữ'}]"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name">
                      </edutalk-option>
                    </edutalk-select>
                    <div class="invalid-feedback" data-field="gender" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
                <div class="mt-2" v-if="checkPassCandidate">
                  <label class="form-label">Cơ chế lương <span class="text-danger">(*)</span></label>
                  <div>

                  </div>
                  <validation-provider vid="salary_mechanism_id" name="Cơ chế lương" rules="required"
                                       v-slot="{ errors, classes}">
                    <edutalk-select id="salary_mechanism_id" v-model="user.profile.salary_mechanism_id" class="w-100"
                                    placeholder="Cơ chế lương" :class="classes" ref="salary_mechanism_id"
                                    name="Cơ chế lương">
                      <edutalk-option
                          v-for="item in mechanisms"
                          :key="item.id"
                          :label="item.name "
                          :value="item.id"
                      >
                      </edutalk-option>
                    </edutalk-select>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mt-2">
                  <label class="form-label">Khu vực <span class="text-danger">(*)</span></label>
                  <validation-provider vid="address" name="Địa chỉ" rules="required" v-slot="{ errors, classes}">
                    <input v-model="user.profile.area" type="text" :class="classes" class="form-control" id="address"
                           placeholder="Khu vực" disabled>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="mt-2" v-if="checkPassCandidate">
                  <label class="form-label">Ngày sinh <span class="text-danger">(*)</span></label>
                  <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                      v-slot="{ errors, classes }">
                    <el-date-picker id="birthDay"
                                    format="dd-MM-yyyy"
                                    ref="birthday"
                                    name="birthday" :class="classes"
                                    value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                                    v-mask="'##-##-####'"
                                    v-model="user.profile.birthday"
                                    :picker-options="pickerOptions"
                                    default-value="2000-10-10"
                                    style="width: 100%;"
                    ></el-date-picker>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <h3 class="font-weight-bold">Thông tin tài khoản nội bộ</h3>
              <div>
                <div class="col-md-4 p-0">
                  <label class="form-label">Email đăng nhập <span class="text-danger">(*)</span></label>
                  <validation-provider vid="name" name="Tên" rules="required" v-slot="{ errors, classes }">
                    <input type="text" class="form-control" id="name" placeholder="Email đăng nhập"
                           autocomplete="false" v-model="user.email" :class="classes" disabled>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4" v-if="verifying && checkCandidate">
            <!-- info indentity -->
            <div class="form-row">
              <div class="col-md-8 mb-3">
                <label class="form-label">Họ và tên <span class="text-danger">(*)</span></label>
                <validation-provider vid="name" name="Tên" rules="required" v-slot="{ errors, classes }">
                  <input type="text" class="form-control" id="name" placeholder="Họ và tên"
                         autocomplete="false" v-model="user.name" :class="classes" disabled>
                  <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
              <div class="col-md-8 mb-3">
                <label class="form-label">Địa chỉ <span class="text-danger">(*)</span></label>
                <validation-provider vid="address" name="Địa chỉ" rules="required" v-slot="{ errors, classes}">
                  <input v-model="user.profile.address" type="text" :class="classes" class="form-control" id="address"
                         placeholder="Nhập địa chỉ thường trú">
                  <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
              <div class="col-md-8 mb-3">
                <label class="form-label">Giới tính<span class="text-danger">(*)</span></label>
                <ValidationProvider vid="gender" name="Giới tính" rules="required"
                                    v-slot="{ errors, classes }">
                  <edutalk-select id="gender" v-model="user.profile.gender" class="w-100"
                                  placeholder="Chọn giới tính" :class="classes" ref="gender" name="gender">
                    <edutalk-option
                        v-for="item in [{id: 1, name: 'Nam'}, { id: 0, name: 'Nữ'}]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                    </edutalk-option>
                  </edutalk-select>
                  <div class="invalid-feedback" data-field="gender" data-validator="notEmpty">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-8 mb-3">
                <label class="form-label">Ngày sinh <span class="text-danger">(*)</span></label>
                <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                    v-slot="{ errors, classes }">
                  <el-date-picker id="birthDay"
                                  format="dd-MM-yyyy"
                                  ref="birthday"
                                  name="birthday" :class="classes"
                                  value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                                  v-mask="'##-##-####'"
                                  v-model="user.profile.birthday"
                                  :picker-options="pickerOptions"
                                  default-value="2000-10-10"
                                  style="width: 100%;"
                  ></el-date-picker>
                  <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-sm-4 verifying-class" v-else-if="!verifying && !reject">
            <i :class="{ 'el-icon-success': verified, 'el-icon-s-promotion': !verified }"></i>
            {{
              !verified ?
                  'Gửi thông tin xác minh danh tính thành công.' :
                  'Đã được xác minh danh tính'
            }}
          </div>
          <div class="col-sm-4 verifying-class" v-else-if="!verifying && reject">
            <i class="el-icon-warning text-warning"></i>
            Thông tin xác minh của bạn bị từ chối. <br> Lí do: {{ user.reason }} <span class="text-info reject"
                                                                                       @click="reUpload">Upload lại thông tin ngay</span>
          </div>
        </div>
      </div>
      <p class="text-muted fs-xs opacity-70 text-center"><i class="el-icon-info" style="margin-right: 10px"></i>Thông
        tin này chỉ được dùng để xác minh danh tính và sẽ được Edutalk giữ an toàn.</p>
    </div>
    <div class="card-footer" v-if="verifying">
      <el-button type="success" icon="el-icon-s-promotion"
                 :disabled="stt < 3 || !checkInfoIndentity || (currentUser.profile.account_type.id == '29' && !checkPassCandidate)" :loading="loading"
                 @click="startUpload" class="float-right">Gửi thông tin xác minh
      </el-button>
    </div>
    </form>
      </ValidationObserver>
  </div>
</template>

<script>
import _ from "lodash-es"
import {POST_VERIFY_IDENTITY, USER_PROFILE, USER_REQUEST_IDENTITY} from "@/core/services/store/profile.module";
import JwtService from "@/core/services/jwt.service";
import {mapGetters, mapState} from "vuex";
import {mechanisms, mechanisms2} from "@/core/config/mechanism";

export default {
  name: "EdutalkIdentity",
  data() {
    return {
      classDefine: {
        false: 'flex-row-reverse',
        true: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      dialogImageUrl: '',
      dialogVisible: false,
      hideInputUpload: false,
      verifying: true,
      verified: false,
      reject: false,
      loading: false,
      textGuideUpload: 'mặt trước thẻ căn cước',
      fileList: [],
      headers: {
        Authorization: `Bearer ${JwtService.getToken()} `
      },
      URLUpload: POST_VERIFY_IDENTITY,
      imageUrl: false,
      listOder: ['mặt trước thẻ căn cước', 'mặt sau thẻ căn cước', 'ảnh cá nhân '],
      stt: 0,
      user: {
        id: '',
        name: '',
        email: '',
        profile: {
          id: '',
          gender: '',
          birthday: '',
          address: '',
          phone: '',
          area: '',
          salary_mechanism_id: '',
          branch: ''
        },
      },
      mechanisms: [],
      checkCandidate: true,
      checkPassCandidate: false,
      test: 'ko có'
    }
  },
  mounted() {
    this.getProfileUser();
  },
  computed: {
    checkInfoIndentity() {
      return this.user.name != "" && this.user.profile.gender != "" && this.user.profile.birthday != "" && this.user.profile.address != "";
    },
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser'])
  },

  methods: {
    getProfileUser() {
      this.$store.dispatch(USER_PROFILE).then((res) => {
        let area_type = res.data && res.data.profile && res.data.profile.area ? res.data.profile.area.type_id : '';
        let candidate_type = res.data && res.data.profile && res.data.profile.candidate && res.data.profile.candidate.type;

        // 1: trung tam , 2: tinh
        if (area_type == 1) {
          this.mechanisms = mechanisms2;
        } else {
          this.mechanisms = mechanisms;
        }

        if(candidate_type == 6) {
          this.mechanisms = mechanisms2;
        }

        this.user = {
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          reason: res.data.reason,
          profile: {
            id: res.data.profile.id,
            gender: res.data.profile.gender,
            birthday: (res.data.profile.birthday && res.data.profile.birthday != "0000-00-00") ? res.data.profile.birthday.split("-").reverse().join("-") : '',
            address: res.data && res.data.profile ? res.data.profile.address : '',
            area: res.data && res.data.profile && res.data.profile.area ? res.data.profile.area.name : '',
            phone: res.data.phone,
            salary_mechanism_id: res.data && res.data.profile ? res.data.profile.salary_mechanism_id > 0 ? res.data.profile.salary_mechanism_id : '' : '',
            branch: res.data && res.data.profile && res.data.profile.branch ? res.data.profile.branch.name_alias  : '',
          },
        }
        // 3 la hoan thanh khoa hoc cua ung vien
        if (res.data.profile?.candidate) {
          this.checkCandidate = false;
          if (res.data.profile?.candidate.status == 3) {
            this.checkPassCandidate = true;
          }
        }

        let status_verify = parseInt(res.data.profile?.status_verify);
        if (status_verify === 0) {
          this.verifying = true
        }
        if (status_verify === 1) {
          this.verifying = false;
          this.verified = false
        }
        if (status_verify === 2) {
          this.verifying = false;
          this.verified = true
        }
        if (status_verify === 3) {
          this.verifying = false;
          this.verified = true;
          this.reject = true;
        }
      });

    },
    checkIdentity() {
      return this.user.profile.status_verify == 0 || this.user.profile.status_verify == 3
    },
    reUpload() {
      this.verifying = true;
    },
    handleRemove(file) {
      if (this.fileList.length - this.stt > 1) {
        return;
      }
      this.listOder.unshift(file.type_upload);
      this.hideInputUpload = false;
      this.textGuideUpload = this.listOder[0];

      let index = this.fileList.findIndex((n, key) => {
        return n.url === file.url;
      });
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
      if (file.type_upload) {
        this.stt--;
      }
      if (this.stt >= 3) {
        this.hideInputUpload = true
      }
      this.verify_images = this.fileList.length;

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file) {
      const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      const isImage2M = file.size / 1024 / 1024 < 2;

      let lastUpdate = _.remove(this.listOder, function (n, key) {
        return key === 0;
      });
      if (this.listOder.length > 0) {
        this.textGuideUpload = this.listOder[0];
      }
      if (lastUpdate.length > 0) {
        file.type_upload = lastUpdate[0];
      }
      this.fileList.push(file);
      this.stt++;

      this.verify_images = this.fileList.length;
      if (!isImage2M) {
        this.$message.error('File upload không được phép > 2Mb');
        this.handleRemove(file);
      }

      if (!allowedExtensions.includes(fileExtension)) {
        this.$message.error('Chỉ được chọn file ảnh (.jpeg, .png, .gif)');
        this.handleRemove(file);
      }
        if (this.stt >= 3) {
            this.hideInputUpload = true
        }
    },
    startUpload() {
      const form = new FormData();
      this.fileList.forEach((file, key) => {
        form.append(`file_${key}`, file.raw)
      });
      form.append('user_id', this.user.id);
      form.append('profile_id', this.user.profile.id);
      form.append('name', this.user.name);
      form.append('gender', this.user.profile.gender ?? '');
      form.append('address', this.user.profile.address);
      form.append('birthday', this.user.profile.birthday);
      if (!this.checkCandidate) {
        form.append('salary_mechanism_id', this.user.profile.salary_mechanism_id);
        form.append('check_candidate', this.checkCandidate);
      }
      this.loading = true;
      this.$store.dispatch(USER_REQUEST_IDENTITY, form)
          .then((response) => {
            this.verifying = false;
            this.verified = false;
            this.reject = false;
            this.currentUser.profile.status_verify = 1;
            this.$message({type: 'success', message: `${response.message}`, showClose: true});
          })
          .catch((error) => {
              if (error.data?.data?.message_validate_form) {
                  this.$refs.form.setErrors(error.data.data.message_validate_form);
              }
              this.$notify({title: 'Thất bại', message: error.data.message, type: 'error'});
          })
          .finally(() => {
            this.loading = false;
          })
    },
    // checkClassCandidate() {
    //     let text;
    //     if (this.checkCandidate) {
    //       text = '';
    //     } else {
    //       text = 'flex-row-reverse';
    //     }
    //     console.log(text)
    //     return text;
    // }
  },
};
</script>
